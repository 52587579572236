<template lang="pug">
  v-card
    .settings-modal
      v-card-title
        h2.card-title {{ 'modals.mixer.settings.title' | translate }}
      v-card-content
        v-text-field.mb-3(
          :label="$t('base.name')"
          v-model="form.name")

      v-card-actions
        v-btn(
          :disabled="!isCodeValid"
          :loading="loading"
          @click="onUpdate") {{ 'actions.save' | translate }}

</template>

<script>
export default {
  name: 'SettingsRecipesModal',

  props: {
    item: Object
  },

  data: () => ({
    form: {
      code_1c: null
    },
    loading: false
  }),

  computed: {
    isCodeValid () {
      return !!this.form.code_1c
    }
  },

  mounted () {
    this.form.code_1c = this.item.code_1c
  },

  methods: {
    async onUpdate () {
      this.loading = true
      const payload = {
        ...this.form
      }
      const { data, success } = await this.$store.dispatch('updateRecipe', { id: this.item.id, payload })
      this.loading = false
      if (success) {
        this.$emit('close')
      } else {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(this.$t(`errors.${msg}`), {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
